<template>
  <span>
    <span v-if="unit.unitType === 'text'" :style="unitStyle">{{ (translator) ? translator(unit.text) : unit.text }}</span>
    <span v-else-if="unit.unitType === 'img' && imgNotEmpty(unit)">
      <img :src="getImgPath(unit)" :id="uid" :width="unit.width" :height="unit.height" />
      <b-tooltip :target="uid" :title="unit.tooltip" v-if="unit.tooltip !== ''" :placement="(parentTblStacked) ? 'right' : 'left'" />
    </span>
    <span v-else-if="unit.unitType === 'table'"><LTChildTable :content="unit.table" :translator="translator" /></span>
  </span>
</template>

<script>
import LTChildTable from "@/components/livetiming/LTChildTable.vue";
import { v4 as uuidv4 } from 'uuid';
import * as path from 'path';

export default {
  props: {
    "unit" : {},
    "translator" : {
       default: null
     },
    "parentTblStacked" : {
      default: false
    },
  },
  components: {
    LTChildTable
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      uid: ''
    }
  },
  computed: {
    unitStyle() {
      if (this.unit.font) {
        const font = this.unit.font;
        let style = "";
        if (font.name)
          style = style + "font-family: " + font.name + ';';
        if (font.size)
          style = style + "font-size: " + font.size + 'pt;';
        if (Array.isArray(font.style)) {
          if (font.style.includes("b"))
            style = style + "font-weight: bold;";
          if (font.style.includes("i"))
            style = style + "font-style: italic;";
        }
        if (font.color)
          style = style + "color: " + font.color;
        return style;
      }
      return null;
    }
  },
  created() {
    this.uid = uuidv4();
  },
  methods: {
    imgNotEmpty(unit) {
      if (!unit.img)
        return false;

      var filename = path.basename(unit.img);
      return filename !== '.jpg';
    },

    getImgPath(unit) {
      if (unit.img)
      {
        const path = this.publicPath + 'livetiming/' + unit.img.toLowerCase();
        //console.log(unit.img);
        //console.log(path);
        return path;
        //return require(path);
      }
      return '';
    }
  }
}
</script>
<template>
  <div class="LTTable">
    <table>
      <tr v-for="(r, rIndex) in flatContent.head.rows" :key="'HR'+rIndex">
        <th v-for="(c, cIndex) in r.cells" :key="'HC' + rIndex + '-' + cIndex">
          <LTCell :content="c" :translator="translator" />
        </th>
      </tr>

      <tr v-for="(r, rIndex) in flatContent.body.rows" :key="'BR'+rIndex">
        <td v-for="(c, cIndex) in r.cells" :key="'BC' + rIndex + '-' + cIndex">
          <LTCell :content="c" :translator="translator" />
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
import LTCell from "@/components/livetiming/LTCell.vue";

export default {
  props: {
    "content" : {},
    "translator" : {
       default: null
     },
  },
  components: {
    LTCell
  },
  computed: {
    flatContent() {
      let result = {};
      result.head = this.content.head;
      result.body = {};
      result.body.rows = [];
      for (let ds of this.content.datasets)
        for (let r of ds.rows)
          result.body.rows.push(r);
      return result;
    }
  }
}
</script>

<style scoped>
.LTTable {
  width: 100%;
  text-align: left;
}

.LTTable table {
  width: 100%;
}

.LTTable table th {
  text-align: center;
}
</style>